<template>
    <b-container fluid class="p-0">
        <b-row class="flex-column">
            <b-col>
                <b-row>
                    <div class="list list--first d-flex flex-column flex-xl-row">
                        <div class="list-logo d-block">
                            <img src="@/assets/img/satellite.png" alt="Satellite" />
                        </div>
                        <div class="list-card">
                            <div class="list-card-title text-white text-uppercase">
                                {{ top.title }}
                            </div>
                            <div class="list-card-text text-white">
                                {{ top.content }}
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <div class="list d-flex flex-column flex-xl-row">
                        <div class="list-logo">
                            <img src="@/assets/img/locator.png" alt="Locator" />
                        </div>
                        <div class="list-card">
                            <div class="list-card-title text-white text-uppercase">
                                {{ bottom.title }}
                            </div>
                            <div class="list-card-text text-white">
                                {{ bottom.content }}
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import RightFooter from '@/components/RightFooter'
import uzText from '@/locales/uz.json';
import enText from '@/locales/en.json';
import ruText from '@/locales/ru.json';

export default {
    name: 'RightList',
    components: { RightFooter },
    props: ['locale'],
    data: () => ({
        top: {
            title: '',
            content: ''
        },
        bottom: {
            title: '',
            content: ''
        }
    }),
    mounted() {
        switch (this.locale) {
            case 'ru':
                this.top.title = ruText.top.title;
                this.top.content = ruText.top.content;
                this.bottom.title = ruText.bottom.title;
                this.bottom.content = ruText.bottom.content;
                break;
            case 'en':
                this.top.title = enText.top.title;
                this.top.content = enText.top.content;
                this.bottom.title = enText.bottom.title;
                this.bottom.content = enText.bottom.content;
                break;
            default:
                this.top.title = uzText.top.title;
                this.top.content = uzText.top.content;
                this.bottom.title = uzText.bottom.title;
                this.bottom.content = uzText.bottom.content;
                break;
        }
    }
}
</script>

<style lang="scss">
.list {
    &-card {
        max-width: 450px;

        &-title {
            font-family: Inter, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px;
        }
        &-text {
            font-family: Inter, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            margin-top: 10px;
        }
    }
    &--first {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 576px) {
        &-logo {
            margin-bottom: 15px;

            img {
                max-width: 50px;
            }
        }
    }

    @media screen and (min-width: 992px) {
        &-card {
            &-title {
                font-family: Inter, serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 800;
                line-height: 36px;
                margin-bottom: 15px;
            }
            &-text {
                font-family: Inter, serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }

    @media screen and (max-width: 1199px) {
        &-card {
            margin-top: 1rem;
        }
    }

    @media screen and (min-width: 1200px) {
        &-logo {
            margin-right: 15px;
        }
        &-card {
            margin-top: 0;
        }
        &--first {
            margin-top: 10px;
        }
    }

    @media screen and (min-width: 1440px) {
        margin-bottom: 50px;

        &-logo {
            margin-right: 30px;
        }
        &-card {
            &-title {
                font-family: Inter, serif;
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: 42px;
                margin-bottom: 15px;
            }
            &-text {
                font-family: Inter, serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
        }
        &--first {
            margin-top: 10px;
        }
    }

    @media screen and (min-width: 1920px) {
        margin-bottom: 50px;

        &-logo {
            margin-right: 30px;
        }
        &-card {
            &-title {
                font-family: Inter, serif;
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: 42px;
                margin-bottom: 15px;
            }
            &-text {
                font-family: Inter, serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }
        }
        &--first {
            margin-top: 150px;
        }
    }
}
</style>
<template>
    <right-list locale="ru" />
</template>

<script>
import RightList from '@/components/RightList'

export default {
    name: 'Ru',
    components: { RightList }
}
</script>